import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { AnyPlayerSelect } from './AnyPlayerSelect';
import { winAnyRequestHelper } from './RequestHelper';
import { VisitInfo } from "./VisitInfo";
import { useRemoteJobRunner } from './remote_job_runner';
import { VirtualList } from './VirtualList';
import { fetchHelper } from './fetchHelper';
import { LINK_CLASS_NAME } from './StyleNavLink';


export function WinPathAny(props: any) {
    const [inRandom, setInRandom] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const region = searchParams.get('region');
    const winner = searchParams.get('winner');
    const loser = searchParams.get('loser');

    const onSubmit = async (aRegion: string, aWinner: string, aLoser: string) => {
        navigate(`/wp_any?region=${aRegion}&winner=${aWinner}&loser=${aLoser}`);
    };

    const onRandom = async (aRegion: string) => {
        setInRandom(true);

        const { randomPlayer: r1 } = await fetchHelper('random', {
            region: aRegion,
            getRandomPlayer: true
        });

        const { randomPlayer: r2 } = await fetchHelper('random', {
            region: aRegion,
            getRandomPlayer: true
        });

        setInRandom(false);
        navigate(`/wp_any?region=${aRegion}&winner=${r1}&loser=${r2}&isRandom=true`);
    };


    const isValid = (!!region && !!winner && !!loser);
    const { loading, logLines, result, error } = useRemoteJobRunner<any>("winPathAny", { region, winner, loser }, isValid);

    const resultVisits = useMemo(() => {
        return result?.results?.tracePathResult?.visits;
    }, [result?.results?.tracePathResult?.visits]);

    // console.log("resultVisits", resultVisits);

    const getPlayerPath = (player: string) => {
        return "/wp/" + region + '/' + player;
    }

    const getGamePath = (gameId: string) => {
        return "/game/" + region + '/' + gameId;
    }

    const getBMPath = (gameId: string, summonerName: string) => {
        return `/benchmark/${region}/${gameId}/${summonerName}`;
    }

    let visitInfoRender: any;
    if (resultVisits) {
        const info = { visits: resultVisits, status: "good" };
        visitInfoRender = (<div>
                <VisitInfo info={info} getBMPath={getBMPath} getPlayerPath={getPlayerPath} getGamePath={getGamePath} />
                <div className="mb-4"></div>
                <span className="text-3xl">
                    If you enjoyed this, please <a target="_blank" 
                        className={"bold text-red-500 " + LINK_CLASS_NAME} 
                        href="https://www.reddit.com/r/leagueoflegends/comments/14myvv6/new_website_shows_how_you_beat_any_player_in_lol/"
                    >
                        upvote it on Reddit
                    </a>.  Thanks!
                </span>
            </div>);
        
    } else if (loading) {
        <>Searching (this can take up to 30-45 seconds)...</>
    } else {
        <></>
    }

    return (
        <div>
            {/* <div className="bg-red-100 flex flex-col">
                <span>Span 1</span>
            </div> */}
            <AnyPlayerSelect region={region} winnerName={winner} loserName={loser} onSubmit={onSubmit} onRandom={onRandom} disabled={loading || inRandom} />
            {(logLines && loading) ?
                <div className="text-left bg-yellow-100 flex flex-row justify-center">
                    <VirtualList items={logLines} />
                </div> : <></>}
            {(error) ? error : <>{visitInfoRender}</>}
            <Outlet />
        </div>
    );
}
