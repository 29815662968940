
export async function fetchHelper(path: string, body: any): Promise<any> {
    (window as any).gtag('event', 'page_view', { page_location: window.location, page_path: window.location.pathname })
    const response = await fetch(`/${path}`, {
        method: 'POST',
        body: JSON.stringify(body),
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
        }
    })
    if (response.ok) {
        const results = await response.json();
        // console.log(results);
        return results;
    } else {
        const errMsg = await response.text();
        throw new Error(errMsg);
    }
}
