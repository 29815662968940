

export function HelperText() {
    return (
        <>
            <div><b>I beat someone, who beat someone, ... who beat the #1 player!</b></div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", width: "650px" }}>
                    <small><i>This website shows how you "beat" the #1 player in LoL. It forms a chain of wins from you
                        to the #1 player. It is similar to the "6 degrees of Kevin Bacon", but instead of movies it's
                        victories over the #1 player.</i></small>
                    <div style={{ paddingTop: "20px" }}><b>What do I do Now?</b></div>
                    <ul>
                        <li>Press the "Random" button to see a random win path to the #1 player.</li>
                        <li>In a win path, you can click on Game links to see info for that game, or click on other players to see their win path.</li>
                        <li>Click on a wrench to see a "benchmark", which shows how a player performed compared to similar players (based on Champ Name, Champ Level, and Queue).</li>
                        <li>Enter a region/summoner to search and press "Submit".</li>
                        <li>Click on "Player Stats" to see all of the crawled games for a given player.</li>
                        <li>Click Explore to look around.</li>
                    </ul>
                </div>
            </div>
        </>
    );
}
