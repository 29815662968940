import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import { fetchHelper } from "./fetchHelper";
import { ColDef, RenderCellFunc, SimpleGrid } from "./SimpleGrid";
import { BenchmarkWrench } from "./BenchmarkWrench";
import { StyleNavLink } from "./StyleNavLink";


export function Benchmark() {
    const navigate = useNavigate();
    const params = useParams();
    const region = params.region;
    const gameId = params.gameId;
    const summonerName = params.summonerName;

    const [errMsg, setErrMsg] = useState(null);
    const [report, setReport] = useState(null as any);

    const fetchData = async () => {
        const results = await fetchHelper('getBenchmark', { region, gameId, summonerName })
        if (results.errMsg) {
            setErrMsg(results.errMsg);
        } else {
            setReport(results);
            console.log('Benchmark results', results);
        }
    }

    useEffect(fetchData as any, [region, gameId, summonerName]);

    const renderCell: RenderCellFunc = (_cols: ColDef[], cellData: any, col: number, row: { [key: string]: any }): any => {
        const getBMPath = (aSummonerName: string, aGameId: string, field: string) => {
            return `/benchmark/${region}/${aGameId}/${aSummonerName}?field=${field}`;
        };

        if (["Worst", "Best"].includes(_cols[col].header!)) {
            const newFieldPlayer = _cols[col].header! + "Player";
            const newFieldGame = _cols[col].header! + "GameId";
            const linkPlayer = row[newFieldPlayer];
            const linkGameId = row[newFieldGame];
            const field = row["Field"];
            return (
                <>
                    <StyleNavLink to={getBMPath(linkPlayer, linkGameId, field)}>{cellData}</StyleNavLink>
                </>
            )
        } else {
            return null;
        }
    }

    const gameLink = <StyleNavLink to={`/game/${region}/${gameId}`}>{gameId}</StyleNavLink>
    const summonerLink = <StyleNavLink to={`/wp/${region}/${summonerName}`}>{summonerName}</StyleNavLink>

    return (        
        <main>
            {(errMsg) ? errMsg : <></>}
            {(!errMsg && !report) ? <>Fetching Benchmark {region} / {gameId} / {summonerName}</> : <></>}
            {(!errMsg && report) ? (
                <>
                    <div><BenchmarkWrench/> &nbsp; Benchmark:  <b>{region} / {gameLink} / {summonerLink}</b></div>
                    <div><pre>{report?.header}</pre></div>
                    <SimpleGrid cols={report.cols} rows={report.rows} renderCell={renderCell} />
                    <p/>
                    <div><small><i># Games in benchmark = {report.numRows}, &nbsp; Elapsed {report.elapMS} ms</i></small></div>
                    <div><small><i>* = Percentile is flipped so that lowest = 100.  Therefore, for all percentiles, higher is better.</i></small></div>
                    <div><small><i>The benchmark has randomly selected player-games that match by Champ Name, Champ Level, and queueId.</i></small></div>
                </>
            ) : <></>}
        </main>
    );
}