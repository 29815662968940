import Visits from "./Visits";



function getCreateMsg(dynamicAllowed: boolean, usedDynamic: boolean, doDynamic: boolean): string {
    let createMsg = 'Used pre-created winPath.';
    if (!dynamicAllowed) {
        createMsg = 'Used pre-created winPath only.  High server load prevented trying live API data.';
    } else if (usedDynamic) {
        createMsg = 'Used pre-created winPath enhanced with live API data.';
    } else if (doDynamic) {
        createMsg = 'Used pre-created winPath only.  Fetched live API data, but it did not improve the path.';
    }
    return createMsg;
}


export function VisitInfo(props: any) {
    const { info, getBMPath, getPlayerPath, getGamePath } = props;

    let doRender = <></>;
    if (info?.visits) {
        var winPathCreateStatus = info?.status
        const { dynamicAllowed, doDynamic, usedDynamic, elap } = info
        const createMsg = getCreateMsg(dynamicAllowed, usedDynamic, doDynamic);
        winPathCreateStatus = `${createMsg}  elapMs ${elap}`;
        const winPathStatus = info?.status
        doRender = (<>
            <Visits info={info} visits={info.visits} getBMPath={getBMPath} getPlayerPath={getPlayerPath} getGamePath={getGamePath} />
            <div style={{ "paddingBottom": "8px" }}><small><i>{winPathCreateStatus}</i></small></div>
            <div style={{ "paddingBottom": "8px" }}><small><i>{winPathStatus}</i></small></div>
        </>)
    }

    return (
        <>
            {doRender}
        </>
    );
}