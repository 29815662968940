import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { App } from "./App";
import { GameId } from "./GameId";
import { WinPath } from "./WinPath";
import { WinPathAny } from "./WinPathAny";
import { WinPathShow } from "./WinPathShow";
import { CrawlerStats } from "./CrawlerStats";
import { PlayerStats } from "./PlayerStats";
import { Benchmark } from "./Benchmark";
import { Explore } from "./Explore";
import { Help } from "./Help";
import { ExploreRecentlyCrawled } from "./ExploreRecentlyCrawled";
import { ExploreLongPaths } from "./ExploreLongPaths";
import { WinPathNotify } from "./WinPathNotify";
import './index.css';
import { ResourceReport } from "./ResourceReport";

const rootElement = document.getElementById("root");
render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />}>
                <Route path="wp" element={<WinPath />} >
                    <Route path="/wp/:region/:summonerName" element={<WinPathShow />} />
                </Route>
                <Route path="/wp_any" element={<WinPathAny />}/>
                <Route path="/wp_notify/:region/:summonerName" element={<WinPathNotify />} />
                <Route path="/game/:region/:gameId" element={<GameId />} />
                <Route path="/player/:region/:summonerName" element={<PlayerStats />} />
                <Route path="/benchmark/:region/:gameId/:summonerName" element={<Benchmark />} />
                <Route path="/explore" element={<Explore />} >
                    <Route path="/explore/recent_crawl" element={<ExploreRecentlyCrawled />} />
                    <Route path="/explore/crawler" element={<CrawlerStats />} />
                    <Route path="/explore/long_paths" element={<ExploreLongPaths />} />
                    <Route path="/explore/resources" element={<ResourceReport />} />
                </Route>
                <Route path="/help" element={<Help />} />
                <Route
                    path="*"
                    element={
                        <main>
                            <b>Invalid path</b>
                        </main>
                    }
                />
            </Route>
        </Routes>
    </BrowserRouter>,
    rootElement
);