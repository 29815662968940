import { useNavigate } from "react-router-dom";
import { BasicReport } from "./BasicReport";
import { ColDef, RenderCellFunc } from "./SimpleGrid";
import { StyleNavLink } from "./StyleNavLink";


export function ExploreLongPaths() {
    const navigate = useNavigate();

    const renderCell: RenderCellFunc = (_cols: ColDef[], cellData: any, col: number, row: { [key: string]: any }): any => {
        const getClickPath = (region: string, player: string) => {
            return `/wp/${region}/${player}?static=1`;
        };

        if (col === 3) {
            return (
                <>
                    <StyleNavLink to={getClickPath(row['Region'], row['Long Player'])}>{cellData}</StyleNavLink>
                </>
            )
        } else {
            return null;
        }
    }

    return (
        <>  
            <BasicReport name={"Longest Paths"} queryPath={"longPaths"} renderCell={renderCell}/>
        </>
    );
}
