import React, { Component } from 'react'
import { BenchmarkWrench } from "./BenchmarkWrench";
import { StyleNavLink } from "./StyleNavLink";


// Comment


const padding = "1px";
const paddingLeft = "8px";
const paddingRight = "8px";
const paddingTop = "4px";
const paddingBottom = "4px";

export const Cell = (props) => {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {props.children}
        </div>)
}

export const CellItem = (props) => {
    return (
        <div style={{ padding, paddingLeft, paddingRight, paddingTop, alignSelf: "center" }}>
            {props.children}
        </div>)
}

const nbsp = <React.Fragment>&nbsp;</React.Fragment>

class Player extends Component {
    nameCase(s) {
        return (s || '').split(' ').map(function (word, index) {
            // If it is not the first word only upper case the first char and lowercase the rest.
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        }).join('')
    }


    render() {
        const p = this.props.part
        const stats = p

        const coolStatNames = ['pentaKills', 'quadraKills', 'tripleKills', 'doubleKills']
        const shortStatNames = ['penta', 'quadra', 'triple', 'double']
        const coolStats = []

        for (var i = 0; i < coolStatNames.length; i++) {
            const statName = coolStatNames[i]
            const shortName = shortStatNames[i]
            if (stats[statName] && stats[statName] >= 0) {
                coolStats.push(`${shortName}=${stats[statName]}`)
            }
        }

        var highRank = this.nameCase(p.playerRank)

        const playerCell = <Cell>
            <CellItem>
                <StyleNavLink to={this.props.getBMPath(p.summonerName)}><BenchmarkWrench/></StyleNavLink> &nbsp;
                <b><StyleNavLink to={this.props.getPlayerPath(p.summonerName)}>{p.summonerName}</StyleNavLink></b>
            </CellItem>
            <CellItem>
                <i>{p.championName} {stats.champLevel}</i>
            </CellItem>
            <CellItem>
                <i>{highRank || ''}</i>
            </CellItem>
        </Cell>

        const statsCell = <Cell>
            <CellItem>
                {stats.kills}  /  {stats.deaths} / {stats.assists}
            </CellItem>
            <CellItem>
                Minions {p.totalMinionsKilled}
            </CellItem>
            <CellItem>
                Gold {(Math.round(stats.goldEarned / 100) / 10).toString() + 'k'}
            </CellItem>
        </Cell>

        const coolStatsCell = <Cell>
            <CellItem>
                <i style={{fontSize:"12px"}}>{(coolStats.length > 0) ? "Kills: " + coolStats.join(", ") : nbsp}</i>
            </CellItem>
        </Cell>

        return (
            <td bgcolor={this.props.color}>
                <table cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr vertical-align="top">
                            <td width="200" align="left">
                                {playerCell}
                            </td>
                            <td width="150" align="center">
                                {statsCell}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="3" align="center">
                                {coolStatsCell}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        )
    }
}

class Game extends Component {
    render() {
        const ctx = this
        const game = this.props.game

        const winnerParts = game.participants.filter((p) => { return p.win })
        const loserParts = game.participants.filter((p) => { return !p.win })

        const statsElem = (parts) => {
            let kills = 0;
            let deaths = 0;
            let assists = 0;
            let goldEarned = 0;
            for (let onePart of parts) {
                kills += onePart.kills;
                deaths += onePart.deaths;
                assists += onePart.assists;
                goldEarned += onePart.goldEarned;
            }
            return (<td>
                {kills}  /  {deaths} / {assists} &nbsp; Gold {(Math.round(goldEarned / 100) / 10).toString() + 'k'}
                </td>
            )
        };

        const players = []
        players.push(<tr key={0}><td><b>Winners</b></td><td><b>Losers</b></td></tr>)
        players.push(<tr key={1}>{statsElem(winnerParts)}{statsElem(loserParts)}</tr>)

        for (var i = 0; i < 5; i++) {
            var win = null
            var lose = null
            if (i < winnerParts.length) {
                win = <Player key={"w" + i + 1} part={winnerParts[i]} getBMPath={ctx.props.getBMPath} getPlayerPath={ctx.props.getPlayerPath} color="#cbffa8" />
            }
            if (i < loserParts.length) {
                lose = <Player key={"l" + i + 1} part={loserParts[i]} getBMPath={ctx.props.getBMPath} getPlayerPath={ctx.props.getPlayerPath} color="#ffa8a8" />
            }
            players.push(<tr key={i + 1}>{win}{lose}</tr>)
        }

        const gd = (game.gameDuration > 10_000) ? game.gameDuration / 1000 : game.gameDuration;

        return (
            <div>
                <div><b>Game {game.gameId}</b> &nbsp; {game.gameCreationDate} &nbsp; {Math.round(gd / 60)} mins</div>
                <div><i>{game.queueName} &nbsp; ({game.gameVersion})</i></div>
                <table align="center" cellPadding="5">
                    <tbody>
                        {players}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Game
