import React, { useContext, useEffect } from 'react';
import { FixedSizeList as List } from 'react-window';
import he from 'he';

export const VirtualList = ({ items }: { items: string[] }) => {

    // console.log("dimensions", dimensions);

    const Column = ({ index, style }: { index: any, style: any }) => {
        const s = items[index];
        const safe = he.encode(s).replaceAll("  ", " &nbsp;");
        return (
            <div 
                className={`text-sm pt-1 font-mono whitespace-nowrap ${index % 2 === 0 ? "bg-gray-100" : ""}`} 
                style={style}
                dangerouslySetInnerHTML={{__html: safe}}
            >
            </div>
        )
    };

    const listRef = React.createRef() as any;

    useEffect(() => {
        listRef.current?.scrollToItem(items.length - 1);
    }, [items]);

    return (
        <List
            ref={listRef}
            height={300}       // After switching to MobX, the SizeContainer is now always giving height of 0????
            itemCount={items.length}
            itemSize={30}
            width={"100%"}
        >
            {Column}
        </List>
    )
};
