import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { StyleNavLink } from "./StyleNavLink";


export function Explore() {
    const sep = <>&nbsp; &nbsp;</>;
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === '/explore') {
            navigate('/explore/long_paths');
        }
    });

    return (
        <>
            <StyleNavLink to="/explore/long_paths">Longest Paths</StyleNavLink> {sep}
            <StyleNavLink to={'/explore/recent_crawl'}>Recently Crawled Games</StyleNavLink> {sep}
            <StyleNavLink to="/explore/crawler">Crawler Stats</StyleNavLink> {sep}
            <StyleNavLink to="/explore/resources">Resources</StyleNavLink> {sep}
            <div className="mb-4"/>
            <Outlet/>
        </>
    );
}
