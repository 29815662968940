import { useNavigate } from "react-router-dom";
import { BasicReport } from "./BasicReport";
import { ColDef, RenderCellFunc } from "./SimpleGrid";
import { StyleNavLink } from "./StyleNavLink";


export function ExploreRecentlyCrawled() {
    const navigate = useNavigate();

    const renderCell: RenderCellFunc = (_cols: ColDef[], cellData: any, col: number, row: { [key: string]: any }): any => {
        const getGamePath = (region: string, gameId: string) => {
            return `/game/${region}/${gameId}`;
        };

        if (col === 3) {
            return (
                <>
                    <StyleNavLink to={getGamePath(row['Region'], row['MatchId'])}>{cellData}</StyleNavLink>
                </>
            )
        } else {
            return null;
        }
    }

    return (
        <>  
            <BasicReport name={"Recently Crawled Games"} queryPath={"recentlyCrawledGames"} renderCell={renderCell}/>
        </>
    );
}
