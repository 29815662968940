import { useEffect, useState } from "react";
import { fetchHelper } from "./fetchHelper";


const API_CALL_INTERVAL = 1000;
const MAX_ERROR = 1;



type CompleteFunc<T> = (results: T) => void;


export const useRemoteJobRunner = <T>(fetchPath: string, callProps: any, isValid: boolean, onComplete?: CompleteFunc<T>) => {
    const [logLines, setLogLines] = useState([] as string[]);
    const [result, setResult] = useState(undefined as T | undefined);
    const [error, setError] = useState(undefined as any);
    const [loading, setLoading] = useState(undefined as any);

    useEffect(() => {
        // console.log("useRemoteJobRunner", callProps);

        let lastApiCall = 0;
        let numError = 0;
        let apiCallInFlight = false;
        let timer: any;
        let curLines: string[] = [`Running WinAnyPath request ${JSON.stringify(callProps)}`];        // We keep a copy because state doesn't update inside this function
        setLoading(false);
        setLogLines(curLines);
        setResult(undefined);
        setError(undefined);
        const intervalCallback = async () => {
            if (isValid && Date.now() - lastApiCall >= API_CALL_INTERVAL && !apiCallInFlight) {
                setLoading(true);
                lastApiCall = Date.now();
                apiCallInFlight = true;
                const postData = Object.assign({}, callProps /*simProps*/, { curLine: curLines.length });
                const apiResults = await fetchHelper(fetchPath, postData);
                // console.log("apiResults", apiResults);
                if (apiResults.logLines) {
                    const both = curLines.concat(apiResults.logLines);
                    setLogLines(both);
                    curLines = both;
                }
                if (apiResults.result) {
                    setResult(apiResults.result);
                    if (onComplete) {
                        onComplete(apiResults.result);
                    }
                    setLoading(false);
                    clearInterval(timer);
                }
                if (apiResults.err) {
                    numError++;
                    setError(apiResults.err);
                    if (numError >= MAX_ERROR) {
                        setLoading(false);
                        clearInterval(timer);
                    }
                }
                apiCallInFlight = false;
            }
        };
        const f = async () => {
            timer = setInterval(intervalCallback, 50);
        }
        f();
    }, [isValid, callProps.region, callProps.winner, callProps.loser]);

    return { loading, logLines, result, error };
};

