import { useEffect, useState } from 'react';
import { BasicReport } from './BasicReport';


export function CrawlerStats() {
    return (
        <>  
            <BasicReport name={"Crawler Stats"} queryPath={"report"}/>
        </>
    );
}
