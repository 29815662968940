import { Button } from "./Button";
import { fetchHelper } from "./fetchHelper";
import { useState, useEffect } from 'react';


export const REGIONS = ['br1', 'eun1', 'euw1', 'jp1', 'kr', 'la1', 'la2', 'na1', 'oc1', 'tr1', 'ru'];


export function PlayerSelect(props: any) {
    const paddingBottom = "16px";

    const { summonerNameProp, disabled, onChange, onRandom } = props;
    const regionProp = props.regionProp;

    const [region, setRegion] = useState(regionProp || 'na1');
    const [summonerName, setSummonerName] = useState(summonerNameProp);

    useEffect(() => {
        setRegion(regionProp || 'na1');
        setSummonerName(summonerNameProp);
    }, [regionProp, summonerNameProp]);

    const onButtonSubmit = () => {
        onChange(region, summonerName, false);
    };

    const onButtonRandom = async () => {
        onRandom();
        const { randomPlayer } = await fetchHelper('random', {
            region,
            getRandomPlayer: true
        })
        setSummonerName(randomPlayer);
        onChange(region, randomPlayer, true);
    };

    const onRegionChange = (evt: any) => {
        const newRegion = evt.target.value;
        setRegion(newRegion);
    }

    const onSummonerChange = (evt: any) => {
        const newSummoner = evt.target.value;
        setSummonerName(newSummoner);
    }

    return (
        <div style={{ paddingBottom }}>
            Region: &nbsp;
            <select value={region} onChange={onRegionChange}>
                {REGIONS.map(aRegion => {
                    return (<option key={aRegion} value={aRegion}>{aRegion}</option>)
                })}
            </select>
            &nbsp;
            Summoner: &nbsp;
            <span className="border-black border-2 pb-1 pt-1 pl-1 pr-1">
                <input type="text" value={summonerName || ''} disabled={disabled} onChange={onSummonerChange} />
            </span>
            <Button className="mr-2 ml-2" caption="Submit" enabled={!disabled} onClick={onButtonSubmit}/>
            <Button className="mr-2 ml-2" caption="Random" enabled={!disabled} onClick={onButtonRandom}/>
        </div>
    );
}