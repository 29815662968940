import { useEffect, useState } from 'react';
import { BasicReport } from './BasicReport';


export function ResourceReport() {
    return (
        <>  
            <BasicReport name={"Resource Report"} queryPath={"api/resMgrStats"}/>
        </>
    );
}
