import { NavLink } from "react-router-dom";

export const LINK_CLASS_NAME = "underline decoration-blue-400 decoration-[.08em] cursor-pointer";


export function StyleNavLink(props: { [x: string]: any; to: any; children: any; }) {
    const { to, children, ...remain } = props;
    return (
        <>
            <NavLink className={LINK_CLASS_NAME} to={to} style={({ isActive }) => ({
                color: isActive ? "green" : ""
            })}>
                {children}
            </NavLink>
        </>
    );
}


