import { useEffect, useState } from 'react';
import { fetchHelper } from "./fetchHelper";
import { VisitInfo } from "./VisitInfo";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";


export function WinPathShow(props: any) {
    const navigate = useNavigate();

    const params = useParams();
    const [searchParams] = useSearchParams();
    const region = params.region;
    const summonerName = params.summonerName;
    const isStatic = searchParams.get("static");
    const isRandom = searchParams.get("random");
    const isManual = searchParams.get("manual");        // User typed it into search and pressed "Submit" (vs just clicking a link)
    

    const [visitInfo, setVisitInfo] = useState(null);
    const [errMsg, setErrMsg] = useState(null);


    const fetchData = async (aRegion: string, aSummonerName: string) => {
        setErrMsg(null);
        setVisitInfo(null);
        const results = await fetchHelper('winPath', {
            region: aRegion,
            summonerName: aSummonerName,
            isRandomPlayer: isRandom,
            isStatic,
            isManual
        })
        if (results.errMsg) {
            setErrMsg(results.errMsg);
        } else {
            setVisitInfo(results);
        }
    }

    useEffect((async () => {
        await fetchData(region!, summonerName!);
    }) as any,
        [region, summonerName]);

    const getPlayerPath = (player: string) => {
        // The text box in WinPath doesn't get updated, but mabye that's OK, its a search box.
        return "/wp/" + region + '/' + player;
    }

    const getGamePath = (gameId: string) => {
        return "/game/" + region + '/' + gameId;
    }

    const getBMPath = (gameId: string, summonerName: string) => {
        return `/benchmark/${region}/${gameId}/${summonerName}`;
    }

    const visitInfoRender = (visitInfo) ? <VisitInfo info={visitInfo} getBMPath={getBMPath} getPlayerPath={getPlayerPath} getGamePath={getGamePath} /> : <>Searching (this can take up to 10 seconds)...</>;

    return (
        <main>
            {(errMsg) ? errMsg : <>{visitInfoRender}</>}
        </main>
    );
}
