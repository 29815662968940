import React, { Component } from 'react'
import { Cell, CellItem } from './Game';


export interface ColDef {
    header?: string,
    alignRight?: boolean,
    number?: boolean,
    numberFmt?: string,
    hidden?: boolean,
}


export type RenderCellFunc = (cols: ColDef[], cellData: any, col: number, row: { [key: string]: any }) => any;


export interface SimpleGridProps {
    rows: { [key: string]: any }[];
    sumRow?: { [key: string]: any };
    cols: ColDef[];
    renderCell?: RenderCellFunc;
}


const renderDefaultCell: RenderCellFunc = (cols: ColDef[], cellData: any, col: number, row: { [key: string]: any }): any => {
    let val = cellData;
    if (cols[col].number && val?.toLocaleString) {
        val = val.toLocaleString();
    }

    return (
        <>
            {val}
        </>
    );
};


function SimpleRow(props: any) {
    return (
        <tr style={{ backgroundColor: props.color, outline: "thin solid", outlineColor: "#ffffff" }}>
            {props.cols.filter((col: ColDef) => !col.hidden).map((col: ColDef, idx: number) => {
                const origIdx = props.cols.indexOf(col);
                const cellData = props.row[col.header!];
                const defRender = renderDefaultCell(props.cols, cellData, origIdx, props.row);
                const cell = (props.renderCell) ? props.renderCell(props.cols, cellData, origIdx, props.row) || defRender : defRender; 
                return (
                    <td valign="top" key={idx}>{cell}</td>
                );
            })}
        </tr>
    );
}


function SimpleHeader(props: any) {
    return (
        <tr style={{ backgroundColor: props.color, outline: "thin solid", outlineColor: "#ffffff" }}>
            {props.cols.filter((col: ColDef) => !col.hidden).map((col: ColDef, idx: number) => {
                return (
                    <td valign="top" key={idx}><b>{col.header}</b></td>
                );
            })}
        </tr>
    );
}


export function SimpleGrid(props: SimpleGridProps) {
    const sumFormatter: RenderCellFunc = (cols: ColDef[], cellData: any, col: number, row: { [key: string]: any }) => {
        return (<b>{renderDefaultCell(cols, cellData, col, row)}</b>);
    }
    return (
        <Cell>
            <CellItem>
                <table cellSpacing="0" cellPadding="5" style={{paddingTop: "20px", paddingBottom: "20px"}}>
                    <thead>
                        <SimpleHeader cols={props.cols} />
                    </thead>
                    <tbody>
                        {props.rows.map((row, idx) => {
                            return <SimpleRow row={row} cols={props.cols} key={idx} renderCell={props.renderCell} />
                        })
                        }
                        {(props.sumRow) ? <SimpleRow cols={props.cols} row={props.sumRow} renderCell={sumFormatter} /> : <></>}
                    </tbody>
                </table>
            </CellItem>
        </Cell>
    );
}
