import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import { fetchHelper } from "./fetchHelper";
import { ColDef, RenderCellFunc, SimpleGrid } from "./SimpleGrid";
import { BenchmarkWrench } from "./BenchmarkWrench";
import { StyleNavLink } from "./StyleNavLink";


export function PlayerStats() {
    const params = useParams();
    const region = params.region;
    const summonerName = params.summonerName;

    const [errMsg, setErrMsg] = useState(null);
    const [report, setReport] = useState(null as any);

    const fetchData = async () => {
        const results = await fetchHelper('playerStats', { region, summonerName })
        if (results.errMsg) {
            setErrMsg(results.errMsg);
        } else {
            setReport(results);
        }
    }

    useEffect(fetchData as any,
        [region, summonerName]);

    const rowGetter = (report: any, idx: number): any => {
        return report.rows[idx];
    }

    const rowsCount = (report: any) => {
        return (report) ? report.rows.length : 0;
    }

    const renderCell: RenderCellFunc = (_cols: ColDef[], cellData: any, col: number, _row: { [key: string]: any }): any => {
        const getBMPath = (gameId: any) => {
            return `/benchmark/${region}/${gameId}/${summonerName}`;
        };

        const getGamePath = (gameId: any) => {
            return `/game/${region}/${cellData}`;
        };

        if (col === 1) {
            return (
                <>
                    <StyleNavLink to={getBMPath(cellData)}><span style={{ color: "blue" }}><BenchmarkWrench/></span></StyleNavLink>
                    &nbsp;
                    <StyleNavLink to={getGamePath(cellData)}><span style={{ color: "blue" }}>{cellData}</span></StyleNavLink>
                </>
            )
        } else {
            return null;
        }
    }

    return (
        <main>
            {(errMsg) ? errMsg : <></>}
            {(!errMsg && !report) ? <>Fetching Player Stats {region} / {summonerName}</> : <></>}
            {(!errMsg && report) ? (
                <>
                    <div>Player Stats:  <b>{region} / {summonerName}</b></div>
                    <div><pre>{(report as any)?.reportHeader}</pre></div>
                    <SimpleGrid cols={report.cols} rows={report.rows} renderCell={renderCell} />
                    <div><small><i>Note - this list only includes games that have been crawled, it does NOT show all games for this player</i></small></div>
                </>
            ) : <></>}
        </main>
    );
}