import { useState, useEffect } from 'react';
import { REGIONS } from "./PlayerSelect";
import { Button } from './Button';


export function AnyPlayerSelect(props: any) {
    const paddingBottom = "16px";

    const regionProp = props.region || 'na1';
    const winnerNameProp = props.winnerName || '';
    const loserNameProp = props.loserName || '';

    const { disabled, onSubmit, onRandom } = props;

    const [region, setRegion] = useState(regionProp);
    const [winnerName, setWinnerName] = useState(winnerNameProp);
    const [loserName, setLoserName] = useState(loserNameProp);

    useEffect(() => {
        // console.log('useEffect', {regionProp, winnerNameProp, loserNameProp})
        setRegion(regionProp);
        setWinnerName(winnerNameProp);
        setLoserName(loserNameProp);
    }, [regionProp, winnerNameProp, loserNameProp]);

    const onButtonSubmit = () => {
        onSubmit(region, winnerName, loserName);
    };

    const onRegionChange = (evt: any) => {
        const newRegion = evt.target.value;
        setRegion(newRegion);
    }

    const onWinnerChange = (evt: any) => {
        setWinnerName(evt.target.value);
    }

    const onLoserChange = (evt: any) => {
        setLoserName(evt.target.value);
    }

    const onRandomSubmit = async () => {
        onRandom(region);
    };

    return (
        <div style={{ paddingBottom }}>
            Region: &nbsp;
            <select value={region} onChange={onRegionChange}>
                {REGIONS.map(aRegion => {
                    return (<option key={aRegion} value={aRegion}>{aRegion}</option>)
                })}
            </select>
            &nbsp;
            <span className="mr-2 ml-2">Winner:</span>
            <span className="border-black border-2 pb-1 pt-1 pl-1 pr-1">
                <input type="text" value={winnerName} disabled={disabled} onChange={onWinnerChange} />
            </span>
            <span className="mr-2 ml-2">Loser:</span>
            <span className="border-black border-2 pb-1 pt-1 pl-1 pr-1">
                <input type="text" value={loserName} disabled={disabled} onChange={onLoserChange} />
            </span>
            <Button className="mr-2 ml-2" caption="Submit" enabled={!disabled} onClick={onButtonSubmit} />
            <Button className="mr-2 ml-2" caption="Random" enabled={!disabled} onClick={onRandomSubmit} />
        </div>
    );
}