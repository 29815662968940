import { useEffect, useState } from 'react';
import { fetchHelper } from './fetchHelper';
import { ColDef, RenderCellFunc, SimpleGrid } from './SimpleGrid';


export interface BasicReportProps {
    name: string;
    queryPath: string;                  // This is the path used to fetch the data from the server, returns BasicReportResults
    renderCell?: RenderCellFunc;
}


export interface BasicReportResults {
    rows: { [key: string]: any }[];
    sumRow?: { [key: string]: any };
    cols: ColDef[];
    header?: string;
    footer?: string;
    elapMS?: number;
}


export function BasicReport(props: BasicReportProps) {

    const [errMsg, setErrMsg] = useState(null as string | null);
    const [report, setReport] = useState(null as BasicReportResults | null);

    const fetchData = async () => {
        console.log(`${props.name} fetching ${props.queryPath}`);
        const results = await fetchHelper(props.queryPath, {})
        if (results.errMsg) {
            setErrMsg(results.errMsg);
        } else {
            setReport(results);
        }
    }

    useEffect(fetchData as any, []);

    const reportElem = (report) ? (
        <>
            {report.header}
            <SimpleGrid rows={report.rows} cols={report.cols} renderCell={props.renderCell} sumRow={report.sumRow}/>
            {report.footer}
            <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
                <div style={{ display: "flex", flexDirection: "column", width: "650px", paddingBottom: "20px" }}>
                    <small><i>Elap {report.elapMS}</i></small>
                </div>
            </div>
        </>
    ) : <>Fetching Report {props.name}</>;

    return (
        <main>
            {(errMsg) ? errMsg : reportElem}
        </main>
    );
}
