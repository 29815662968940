import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { LINK_CLASS_NAME, StyleNavLink } from "./StyleNavLink";


export function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const sep = <>&nbsp; &nbsp;</>;

    // We want the WinPath and Player routes to link to each other based on current region/player
    const path = location.pathname;
    const parts = path.split("/");
    let region = '';
    let summonerName = '';    
    if (path.startsWith('/player') || path.startsWith('/wp')) {
        region = parts[2];
        summonerName = parts[3];
    } else if (path.startsWith('/benchmark')) {
        region = parts[2];
        summonerName = parts[4];
    }

    const wpPath = (region && summonerName) ? `/wp/${region}/${summonerName}` : '/wp';
    const playerPath = (region && summonerName) ? `/player/${region}/${summonerName}` : '/player';


    useEffect(() => {
        if (location.pathname === '/') {
            navigate('/wp');
        }
    });

    return (
        <div style={{ textAlign: "center" }}>
            <div className="text-2xl mb-4">I Beat the Best in League of Legends</div>
            <nav
                style={{
                    paddingBottom: "1rem"
                }}
            >
                <StyleNavLink to={wpPath}>Win Path</StyleNavLink> {sep}
                <StyleNavLink to={'/wp_any'}>Win v.s. Anyone</StyleNavLink><span className="text-xs align-super text-fuchsia-600">(New)</span> {sep}
                {(playerPath !== '/player') ? ([<StyleNavLink to={playerPath}>Player Stats</StyleNavLink>, sep]) : <></>}
                <StyleNavLink to="/explore">Explore</StyleNavLink> {sep}
                <StyleNavLink to="/help">Help</StyleNavLink> {sep}
                <a className={LINK_CLASS_NAME} href="https://www.reddit.com/r/lol_beat_best/" target="_blank">Discuss</a>
            </nav>
            <Outlet />
            {/* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", "paddingBottom": "12px", "paddingTop": "12px" }}>
                <div style={{ alignItems: "center", alignSelf: "center", width: "650px" }}>
                    <small><i>
                        Lol-Beat-Best.com isn't endorsed by Riot Games and doesn't reflect the views or opinions of Riot Games or anyone officially involved in producing or managing Riot Games properties. Riot Games, and all associated properties are trademarks or registered trademarks of Riot Games, Inc.
                    </i></small>
                </div>
            </div>
            {/* AddToAny BEGIN */}
            <div className="a2a_kit a2a_kit_size_32 a2a_default_style">
                <a className="a2a_dd" href="https://www.addtoany.com/share"></a>
                <a className="a2a_button_facebook"></a>
                <a className="a2a_button_twitter"></a>
                <a className="a2a_button_reddit"></a>
                <a className="a2a_button_email"></a>
            </div>
            {/* AddToAny END */}
        </div>
    );
}
