import { useState } from 'react';
import { PlayerSelect } from "./PlayerSelect";
import { useNavigate, useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { HelperText } from './Helpertext';


export function WinPath(props: any) {
    const navigate = useNavigate();

    const params = useParams();
    const region = params.region;
    const summonerName = params.summonerName;

    const [fetching, setFetching] = useState(false);

    const playerSelectChange = async (aRegion: string, aSummonerName: string, aWasRandom: boolean) => {
        if (aWasRandom) {
            navigate("/wp/" + aRegion + '/' + aSummonerName + "?random=1");
        } else {
            navigate("/wp/" + aRegion + '/' + aSummonerName + "?manual=1");
        }
        setFetching(false);
    };

    const onRandom = () => {
        setFetching(true);
    }

    return (
        <div>
            <PlayerSelect onChange={playerSelectChange} onRandom={onRandom} regionProp={region} summonerNameProp={summonerName} disabled={fetching} />
            {(!region || !summonerName) ? <HelperText/> : <></>}
            <Outlet/>
        </div>
    );
}
