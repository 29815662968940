

function Question(props) {
    return (
        <>
            <div style={{ flex: 1, alignSelf: "flex-start", paddingTop: "20px", paddingBottom: "10px" }}><b>{props.title}</b></div>
            <div style={{ flex: 1, alignSelf: "flex-start", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                {props.children}
            </div>
        </>
    );
}


function Answer(props) {
    return (
        <div>
            {props.children}
        </div>
    );
}


function LinkItem(props) {
    return (
        <div>
            <a href={props.link}>{props.title}</a> - {props.desc}
        </div>
    );
}


export function Help() {
    return (
        <>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", width: "650px", paddingBottom: "20px" }}>
                    <div style={{ paddingTop: "20px", width: "600px", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                        <b>Frequently Asked Questions</b>
                        <Question title="What is a Win Path?">
                            <Answer>
                                Its a path of wins from a player to the #1 player on a region.  It shows how the first player "beat" the #1 player.  Its similar to
                                the <a href="https://en.wikipedia.org/wiki/Six_Degrees_of_Kevin_Bacon">6-degrees of Kevin Bacon</a> for movies.
                            </Answer>
                        </Question>
                        <Question title="What is the report when you click on a Wrench?">
                            <Answer>
                                Clicking the wrench shows a "Benchmark" report.  This shows how that player's stats compare against a "benchmark", which
                                is just a set of players that match up by a certain criteria.  Currently the criteria is to match by Champion Name (e.g. Mundo),
                                Champion Level (e.g. 18), and Queue (e.g., "5v5 Ranked Solo").  The benchmark is random 25 players.
                                <p/>
                                This lets you compare each stat against a usual range.
                            </Answer>
                        </Question>
                        <Question title="What is the subreddit for lol-beat-best.com">
                            <LinkItem link="https://www.reddit.com/r/lol_beat_best/" title="/r/lol_beat_best" desc="lol-beat-best.com Subreddit" />
                        </Question>
                        <Question title="What tech is used to power the website?">
                            <LinkItem link="https://developer.riotgames.com/" title="Riot Developer API" desc="accesses Player & Game Info" />
                            <LinkItem link="http://rocksdb.org/" title="RocksDB" desc="stores all data (key/value store)" />
                            <LinkItem link="https://nodejs.org/en/" title="Node.js" desc="backend queries" />
                            <LinkItem link="https://www.typescriptlang.org/" title="Typescript" desc="language used in frontend/backend" />
                            <LinkItem link="https://reactjs.org/" title="React" desc="frontend framework" />
                            <LinkItem link="https://www.java.com/en/" title="Java" desc="used to wrap RocksDB behind a REST server." />
                        </Question>
                    </div>
                </div>
            </div>
        </>
    );
}
