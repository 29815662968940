import React, { Component } from 'react'
import { BenchmarkWrench } from "./BenchmarkWrench";
import { StyleNavLink } from "./StyleNavLink";
import { useLocation } from "react-router-dom";


const padding = "1px";
const paddingLeft = "8px";
const paddingRight = "8px";
const paddingTop = "4px";
const paddingBottom = "4px";

const cell = (children) => {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ padding, paddingLeft, paddingRight, paddingTop, alignSelf: "flex-end" }}>
                {children}
            </div>
        </div>)
}

class Visit extends Component {

    render() {
        // console.log("render = " + JSON.stringify(this.props));
        const visit = this.props.visit


        const numBox = cell(
            <i>{this.props.idx}</i>
        )

        const beatBox = cell(
            <i>{(visit.actualWin ? 'beat' : <span style={{ color: "red" }}>LOST</span>)}</i>
        )

        const onBox = cell(
            <i>on</i>
        )

        const gameBox = (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ padding, paddingTop }}>
                    <b>{visit.gameDateStr}</b>
                </div>
                <div style={{ padding }}>
                    <i><StyleNavLink to={this.props.getGamePath(visit.fromGameId)}>Game {visit.fromGameId}</StyleNavLink></i>
                </div>
                <div style={{ padding }}>
                    <small><i>{visit.queueTypeStr}</i></small>
                </div>
                <div style={{ padding, paddingBottom }}>
                    <small><i>{visit.gameDurationStr}</i></small>
                </div>
            </div>)


        const playerBox = (part, playerRank) => {
            const { summonerName, championName, champLevel, kills, deaths, assists } = (part || {})
            console.log("summonerName", summonerName);
            return (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ padding, paddingTop }}>
                        <StyleNavLink to={this.props.getBMPath(visit.fromGameId, summonerName)}><BenchmarkWrench /></StyleNavLink>
                        &nbsp; &nbsp;
                        <b><StyleNavLink to={this.props.getPlayerPath(summonerName)}>{summonerName}</StyleNavLink></b>
                    </div>
                    <div style={{ padding }}>
                        <small><i>{championName} {champLevel}</i></small>
                    </div>
                    <div style={{ padding }}>
                        <small><i>{playerRank}</i></small>
                    </div>
                    <div style={{ padding }}>
                        <small><i>{kills} / {deaths} / {assists}</i></small>
                    </div>
                </div>)
        }

        return (
            <tr bgcolor={this.props.color} style={{ outline: "thin solid", outlineColor: "#ffffff" }}>
                <td valign="top">{numBox}</td>
                <td valign="top">{playerBox(visit.winPart, visit.winPlayerRank)}</td>
                <td valign="top">{beatBox}</td>
                <td valign="top">{playerBox(visit.losePart, visit.losePlayerRank)}</td>
                <td valign="top">{onBox}</td>
                <td valign="top">{gameBox}</td>
            </tr>
        )
    }
}

export function Visits(props) {
    const location = useLocation();

    let visitComps

    const rankedColors = ["#cbffa8"]
    const unrankedColors = ["#a8ecff"]
    const topPlayer = (props.visits.length > 0) ? props.visits[props.visits.length-1].losePart?.summonerName : null;
    const notifyLink = `/wp_notify/${props.info.region}/${props.info.summonerName}?curSteps=${props.visits.length}&topPlayer=${encodeURI(topPlayer)}`

    if (props.visits) {
        var idx = 0
        visitComps = props.visits.map(function (oneVisit) {
            console.log("oneVisit", oneVisit);
            idx += 1
            var color = (oneVisit.queueRanked) ? rankedColors[idx % 1] : unrankedColors[idx % 1];
            return <Visit idx={idx} visit={oneVisit} key={oneVisit.fromGameId} getBMPath={props.getBMPath} getPlayerPath={props.getPlayerPath} getGamePath={props.getGamePath} color={color} />
        })
        const innerLegend = cell(
            <React.Fragment>
                <span style={{ 'backgroundColor': rankedColors[0], padding: "4px", margin: "5px" }}>Ranked</span>
                &nbsp;
                <span style={{ 'backgroundColor': unrankedColors[0], padding: "4px", margin: "5px" }}>Unranked</span>
            </React.Fragment>
        )
        const legend = (
            <tr key="legend">
                <td colSpan="2">
                    <i><small><StyleNavLink to={notifyLink}>Notify on WinPath Change</StyleNavLink></small></i>
                </td>
                <td colSpan="4">
                    {innerLegend}
                </td>
            </tr>
        )
        visitComps.push(legend)
    } else {
        visitComps = null
    }
    if (props.visits && props.visits.length === 0) {
        visitComps = (<div>This player does not have a Win Path to the #1 Player</div>)
    }

    return (
        <>
            <div>
                <table align="center" cellSpacing="0" cellPadding="5">
                    <tbody>
                        {visitComps}
                    </tbody>
                </table>
                <p />
            </div>
            
        </>
    )
}

export default Visits
