import { useParams, useSearchParams } from "react-router-dom";
import { useState } from 'react';


function FormRow(props: any): JSX.Element {
    return (
        <div style={{ paddingTop: "8px", paddingBottom: "8px", ...props.style }}>
            {props.children}
        </div>
    )
}


export function WinPathNotify(props: any): JSX.Element {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const region = params.region;
    const summonerName = params.summonerName;
    const curSteps = searchParams.get("curSteps");
    const topPlayer = searchParams.get("topPlayer");
    const notes = `Notification emails have a 1-click link to unsubscribe.  Your data will NOT be sold and is kept private.  IP address used on sign-up is logged to help prevent spam.`;


    const [email, setEmail] = useState('');
    const [notifyType, setNotifyType] = useState('any');
    const [expire, setExpire] = useState(0);
    const [maxEmail, setMaxEmail] = useState(1);
    const [saved, setSaved] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const validate = (): boolean => {
        let err = '';
        if (email === '') {
            err = 'Email can not be blank';
        }
        if (!/\S+@\S+\.\S+/.test(email)) {
            err = 'Email is not valid';
        }
        setErrMsg(err);
        return err === '';
    }

    const onSubmit = async () => {
        const data = { email, notifyType, expire, maxEmail, region, summonerName, curSteps, topPlayer };
        console.log('onSubmit', data);
        if (!validate()) {
            return;
        }
        setSubmitting(true);
        const response = await fetch(`/saveNotify`, {
            method: 'POST',
            body: JSON.stringify(data),
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            }
        })
        setSubmitting(false);
        if (response.ok) {
            const results = await response.json();
            setSaved(true);
        } else {
            const errMsg = await response.text();
            setErrMsg(errMsg);
        }
    }

    const onTextChange = (e: any) => {
        setEmail(e.target.value);
    }

    const onNotifyTypeChange = (e: any) => {
        setNotifyType(e.target.value);
    }

    const onExpireChange = (e: any) => {
        setExpire(e.target.value);
    }

    const onMaxEmailChange = (e: any) => {
        setMaxEmail(e.target.value);
    }

    return (
        <>
            <FormRow>
                Receive notification when WinPath changes for {summonerName} on {region}
            </FormRow>
            {errMsg &&
                <FormRow style={{color:"red"}}>
                    <b>{errMsg}</b>
                </FormRow>
            }
            {submitting &&
                <FormRow style={{color:"red"}}>
                    Submitting Notification Request
                </FormRow>
            }
            {saved &&
                <FormRow style={{color:"green"}}>
                    Notification Request was successful
                </FormRow>
            }
            {(!saved && !submitting) && 
                <>
                    <FormRow>
                        Email:  <input name="email" id="email" type="text" onChange={onTextChange} value={email} />
                    </FormRow>
                    <FormRow>
                        <select name="notifyType" id="notifyType" onChange={onNotifyTypeChange}>
                            <option value="any">Notify on any change</option>
                            <option value="shorter">Notify when path shortens</option>
                        </select>
                    </FormRow>
                    <FormRow>
                        <select name="expire" id="expire" onChange={onExpireChange}>
                            <option value="0">Never expire</option>
                            <option value="7">Expire in 7 days</option>
                            <option value="30">Expire in 30 days</option>
                            <option value="90">Expire in 90 days</option>
                            <option value="365">Expire in 365 days</option>
                        </select>
                    </FormRow>
                    <FormRow>
                        <select name="maxEmail" id="maxEmail" onChange={onMaxEmailChange}>
                            <option value="1">Send max 1 email per day</option>
                            <option value="7">Send max 1 email per 7 days</option>
                            <option value="30">Send max 1 email per 30 days</option>
                        </select>
                    </FormRow>
                    <FormRow>
                        {notes}
                    </FormRow>
                    <FormRow>
                        <input name="submit" id="submit" type="button" value="Submit" onClick={onSubmit} />
                    </FormRow>
                </>
            }
        </>
    );
}